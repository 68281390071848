export const environment = {
  production: true,

  APIUrl: 'https://crapisystest.azurewebsites.net/api',
  //ESB
  addressSuggestUrl: 'https://secure.communities.qld.gov.au/apw-systest/p/restful/address/(suggest)/com.cordys.web.rest.RESTGateway.wcp',  // URL for ESB address suggestion (typeahead)
  addressSuggestUrlByPassESB: 'https://crgiswrappersystest.azurewebsites.net/api/arcgis/addresssuggestions',
  addressDetailsUrl: 'https://secure.communities.qld.gov.au/apw-systest/p/restful/address/(details)/com.cordys.web.rest.RESTGateway.wcp',  // URL for ESB address details (geocode)
  addressDetailsUrlByPassESB: 'https://crgiswrappersystest.azurewebsites.net/api/arcgis/addressdetails',
  addressFindByGeoUrl: 'https://secure.communities.qld.gov.au/apw-systest/u/restful/aoi/(findByGeocodes)/(CR)/com.cordys.web.rest.RESTGateway.wcp', // Address polygons (find by geocode)
  addressFindByGeoUrlByPassESB:'https://crgiswrappersystest.azurewebsites.net/api/arcgis/findaddressbygeo',
  disableWizardValidation: false,
  //Auth0
  auth0RegisterRedirectURL: 'https://systest.portal.communityrecovery.qld.gov.au/register/',
  auth0LogoutReturnURL: 'https://dcsywtest02.au.auth0.com/v2/logout?returnTo=https%3A%2F%2Fsystest.portal.communityrecovery.qld.gov.au',
  auth0LoginRedirectURL: 'https://systest.portal.communityrecovery.qld.gov.au/login/',
  auth0LogoutSupportReturnURL: 'https://dcsywtest02.au.auth0.com/v2/logout?returnTo=https%3A%2F%2Fsystest.portal.communityrecovery.qld.gov.au%2Fsupport%3Blogout%3Dtrue',
  auth0clientID: 'mnL82ZRlcpq3nmFEsFaiG9YLHOP0HNpB',
  auth0domain: 'dcsywtest02.au.auth0.com',
  auth0audience: 'https://portal-api',
  versionNum: '15.0.00.5',
  isActivated: true,
  showEnvLabel: true,
  environment: 'SYSTEST-CHDE',
  isGenericGrantEnabled: false,
  bypassESB: true
};
